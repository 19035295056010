import { Link } from "gatsby";
import { motion, AnimatePresence } from "framer-motion";
import React from "react";
import cc from "classcat";
import { FaLinkedin, FaEnvelopeSquare } from "react-icons/fa";
import Logo from "../images/logo.svg";
import { Helmet } from "react-helmet";

const Button = ({ className, children, ...props }) => {
  const Component = props.href ? Link : "button";
  return (
    <Component
      {...props}
      className={cc([
        "bg-white rounded-sm border-none outline-none px-3 py-2 text-blue hover:bg-blue hover:text-white text-center",
        className,
      ])}
    >
      {children}
    </Component>
  );
};

const Card = () => {
  const [isFlipped, setIsFlipped] = React.useState(false);
  const [isContactOpen, setIsContactOpen] = React.useState(false);
  return (
    <div className="bg-blue-500 p-7 rounded-sm flex flex-col items-center">
      <div style={{ transformStyle: "preserve-3d", perspective: "1000px" }}>
        <button
          onClick={() => setIsFlipped(!isFlipped)}
          className="relative w-48 h-48 mb-5 mx-10"
        >
          <motion.div
            className="rounded-full bg-white w-48 h-48 flex items-center justify-center absolute top-0 left-0 backface-hidden"
            animate={{ rotateY: isFlipped ? "180deg" : "0deg" }}
            transition={{
              type: "spring",
              damping: 10,
              mass: 1,
            }}
          >
            <Logo className="w-28 h-28" />
          </motion.div>
          <motion.div
            initial={{rotateY: "180deg"}}
            animate={{ rotateY: isFlipped ? "0deg" : "180deg" }}
            transition={{
              type: "spring",
              damping: 10,
              mass: 1,
            }}
            className="rounded bg-blue text-white w-48 h-48 flex justify-start items-end absolute top-0 left-0 backface-hidden p-3 text-left"
            style={{
              transform: "rotateY(-180deg)",
            }}
          >
            <div>
              <span className="bg-white rounded-sm px-3 py-2 text-blue inline-block font-medium mb-3">
                <span className="mr-2">👋</span>
                Hi, I'm Ben!
              </span>
              <p className="text-xs">
                I'm an industrial designer with a passion for solving challenges
                that make cutting edge products possible!
              </p>
            </div>
          </motion.div>
        </button>
      </div>

      <Button
        className="w-full mb-4"
        href="/ben_stibal_resume.pdf"
        target="_blank"
        rel="noopener"
      >
        Resume
      </Button>

      <Button
        className="w-full mb-4"
        href="/ben_stibal_portfolio.pdf"
        target="_blank"
        rel="noopener"
      >
        Portfolio
      </Button>

      <Button
        className="w-full"
        onClick={() => setIsContactOpen(!isContactOpen)}
      >
        Connect
        <div className="overflow-hidden">
          <AnimatePresence>
            {isContactOpen && (
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: "auto" }}
                exit={{ height: 0 }}
              >
                <div className="flex space-x-3 justify-center pt-3">
                  <a href="https://linkedin.com/in/benstibal" className="text-4xl">
                    <FaLinkedin />
                  </a>
                  <a href="mailto:benstibal@gmail.com" className="text-4xl">
                    <FaEnvelopeSquare />
                  </a>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </Button>
    </div>
  );
};

const IndexPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <Helmet>
        <title>Ben Stibal</title>
        <meta name="description" content="I'm an industrial designer with a passion for solving challenges that make cutting edge products possible!" />
        <meta name="author" content="Ben Stibal" />
      </Helmet>
      <Card />
    </div>
  );
};

export default IndexPage;
